<template>
<v-row>
  <v-col cols="12" md="10">
    <search-box
        label="* Órgão"
        popup-label="Selecione um Órgão"
        :id.sync="value.idOrgao"
        :descricaoFormatada.sync="value.descFormatadaOrgao"
        :hidden-clear="true"
        :value="orgaos"
        :loader-fn="loaderOrgaos"
        item-key="id"
        item-text="descricaoFormatada"
        :rules="[
            this.$validators.notNullOrUndefined
        ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum Órgão encontrado
        </p>
      </template>
    </search-box>
  </v-col>
  <v-col cols="12" md="2">
    <v-text-field label="* Código"
                  dense
                  :rules="[
                      this.$validators.number.required,
                        v => this.$validators.string.greaterThanOrEquals(v, 1),
                        v => this.$validators.string.lessThanOrEquals(v, 5)
                      ]"
                  v-model.trim="value.cdUnidade"/>
  </v-col>  
  <v-col cols="12" md="12">
    <v-text-field label="* Descricao"
                  dense
                  :rules="[
                      this.$validators.string.required,
                        v => this.$validators.string.greaterThanOrEquals(v, 1),
                        v => this.$validators.string.lessThanOrEquals(v, 200)
                      ]"
                  v-model.trim="value.descricao"/>
  </v-col>
</v-row>
</template>

<script>
import {findAll as findAllOrgaos} from "../../../api/orgao";
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";

export default {
  name: "UnidadeForm",
  components: {SearchBox, LookupDominio},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      lookups: {
        estado: ItemDeDominio.COMBO_ESTADO, 
      },
      loading: false,
      error: null,
    }
  },  
  computed: {
    orgaos() {
      if (this.value.idOrgao == null) return null;
      return {
        id: this.value.idOrgao,
        descricaoFormatada: this.value.descFormatadaOrgao
      }
    },
  },
  watch: {
  },  
  methods: {
    loaderOrgaos(page, search) {
      return findAllOrgaos(page, {
        nome: search
      })
    },
  }
}
</script>

<style scoped>

</style>